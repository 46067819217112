import React, { Component } from "react";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";
import $ from "jquery";
import {
  Layout,
  Loader,
  HomeClients,
  HomeLineAnimation,
} from "../../components";

class IndexPage extends Component {
  onClientAnimation = () => {
    const plus = $(".line-animation__thumb");
    const isAnimated = plus.hasClass("running");
    if (isAnimated) {
      plus.addClass("spin");
      setTimeout(() => {
        plus.removeClass("spin");
      }, 500);
    }
  };

  render() {
    const {
      pageContext: { slug },
    } = this.props;
    return (
      <Layout
        seo={{
          title: "Grupa ADream - Agencja reklamowa interaktywna w Krakowie",
          href: slug,
          lang: "en",
        }}
        langSwitch={{
          langKey: "pl",
          langSlug: "/",
        }}
        scroll
      >
        <HomeLineAnimation />
        <LazyLoad once offset={1} placeholder={<Loader />}>
          <section className="container-fluid home_section_1">
            <div className="row home_section_1__header align-items-center">
            <div className="col-lg-5 col_1">
                <div className="inner">
                  <h1 className="for_mobile">
                    <span>We&nbsp;&nbsp;create</span> <span>brands &nbsp; and&nbsp;&nbsp;digital </span> <br />{" "}
                     <span>products<sup>*</sup></span>
                    <p className="annotation__mobile"><sup>*</sup>that &nbsp; work</p>
                  </h1>

                  <h1
                    className="glitch glitch--1 words chars splitting for_desktop"
                    data-scroll="out"
                    data-splitting=""
                  >
                  We create brands <br/>
                  and{" "} 
                  <span className="word" data-word="digital">
                    <span className="char" data-char="d">
                      d
                    </span>
                    <span className="char" data-char="i">
                      i
                    </span>
                    <span className="char" data-char="g">
                      g
                    </span>
                    <span className="char" data-char="i">
                      i
                    </span>
                    <span className="char" data-char="t">
                      t
                    </span>
                    <span className="char" data-char="a">
                      a
                    </span>
                    <span className="char" data-char="l">
                      l
                    </span>
                  </span>{" "}
                  <span className="word" data-word="products">
                    <span className="char" data-char="p">
                      p
                    </span>
                    <span className="char" data-char="r">
                      r
                    </span>
                    <span className="char" data-char="o">
                      o
                    </span>
                    <span className="char" data-char="d">
                      d
                    </span>
                    <span className="char" data-char="u">
                      u
                    </span>
                    <span className="char" data-char="c">
                      c
                    </span>
                    <span className="char" data-char="t">
                      t
                    </span>
                    <span className="char" data-char="s">
                      s
                    </span>
                  </span>
                  <sup className="star">*</sup>
                  </h1>
                  <span className="annotation">*that work</span>


                </div>
                <Link to="/en/projects" className="btn_custom">
                    Projects
                </Link>
              </div>
              <div className="col-lg-7 col_2">
                <video
                  width="100%"
                  height="100%"
                  loop
                  autoPlay
                  playsInline
                  muted
                  data-setup="{}"
                >
                  <source
                    src={require("../../assets/video/video_top.mp4")}
                    type="video/mp4"
                  />
                </video>
                <img
                  className="img-fluid"
                  src={require("../../assets/img/home_section_1_a.svg")}
                  alt=""
                />
              </div>
            </div>
          </section>
          <div className="home_mobile_desc">
            <p>
              <span>Competences</span>
              We combine the comprehensive customer service with a high degree
              of specialization in all of the services complementing it. As a
              Digital Agency we offer services such as: <br /> Strategy,
              Branding, UX/UI, Design, Web Development, and Internet Marketing
            </p>
          </div>
        </LazyLoad>
        <section className="container-fluid home_section_2">
          <div className="text-center">
            <h2>Projects</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <Link to="/en/projects/rspwn" className="portfolio_tile">
                <h3>
                  RSPWN.GG <span>Logo / Online Store</span>
                </h3>
                <LazyLoad offset={100}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/portfolio_grid/rspwn_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio_grid/rspwn_front.png")}
                    alt="Projekty RSPWN.GG"
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link to="/en/projects/lewandowski" className="portfolio_tile">
                <h3>
                  Lewandowski Power Team <span>Logo / Packaging design</span>
                </h3>
                <LazyLoad offset={100}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/portfolio_grid/lewandowski_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio_grid/lewandowski_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link
                to="/en/projects/green-mountain-resort"
                className="portfolio_tile gmr"
              >
                <h3>
                  Green Mountain Resort{" "}
                  <span>
                    Website /Presentations / Catalogues / Lead Generation
                    Campaign
                  </span>
                </h3>
                <LazyLoad offset={100}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/portfolio_grid/gmr_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio_grid/gmr_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link to="/en/projects/euforia" className="portfolio_tile">
                <h3>
                  Euphoria Perfumery{" "}
                  <span>Product Marketing Campaign / Case Study</span>
                </h3>
                <LazyLoad offset={350}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/portfolio_grid/euforia_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio_grid/euforia_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link to="/en/projects/gg-brewery" className="portfolio_tile">
                <h3>
                  GG Brewery{" "}
                  <span>Brewery logo / Beer logos / Label design</span>
                </h3>
                <LazyLoad offset={350}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/portfolio_grid/gg_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio_grid/gg_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link to="/en/projects/zmigrod" className="portfolio_tile">
                <h3>
                  New Żmigród{" "}
                  <span>
                    Logo / Branding / Packaging design / Advertising materials /
                    Catalogues / Website
                  </span>
                </h3>
                <LazyLoad offset={350}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/portfolio_grid/zmigrod_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio_grid/zmigrod_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
          </div>
          <div className="home_section_2--action">
            <Link to="/en/projects" className="btn_custom bigger">
              All projects
            </Link>
          </div>
        </section>
        <section className="container-fluid home_section_3">
          <div className="text-center">
            <h2>Our Clients</h2>
          </div>
          <HomeClients onClientAnimation={() => this.onClientAnimation()} />
        </section>
      </Layout>
    );
  }
}

export default IndexPage;
